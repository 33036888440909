import axios from 'axios';
import fhir from 'lifen-fhir.js/src/fhir';
import { clone } from 'lodash';
import qs from 'qs';

import { ApiDomain, getApiUrl } from './utils';
import { getIdFromReference } from './utils/fhir';
import { logger } from './utils/logger';

const authInfo = {
  token: '',
  user: {}
};
export function setAuthInfo(newToken: string, user: any) {
  authInfo.token = newToken;
  authInfo.user = user;
}
export function getAuthInfo() {
  return clone(authInfo);
}

const fhirAxiosClient = axios.create();

const adapter = {
  http: (args: any) => {
    args.headers.Authorization = `Bearer ${authInfo.token}`;
    return fhirAxiosClient(args);
  }
};
export function $fhir(apiDomain: ApiDomain, additionalHeader: any = {}): fhir.FhirClient {
  const config = {
    baseUrl: `${getApiUrl(apiDomain)}/fhir`,
    headers: {
      'x-api-version': 2,
      ...additionalHeader
    }
  };
  return fhir(config, adapter);
}

interface FhirError extends Error {
  traceId?: string;
}

function headersBuilder(isJsonContentType: boolean = false) {
  // TODO: Rename function
  // tslint:disable-next-line:no-shadowed-variable
  const headers = {
    Accept: '',
    Authorization: '',
    'Content-Type': '',
    'x-api-version': '2'
  };
  headers.Authorization = `Bearer ${authInfo.token}`;

  if (!isJsonContentType) {
    headers.Accept = 'application/json;q=0.9,image/webp,*/*;';
    headers['Content-Type'] = 'application/json';
  }

  return headers;
}

interface SearchParams {
  recipient?: string;
  _include?: string[];
  _sort?: string;
  _count?: number;
  _getpagesoffset?: number;
  status?: string;
  medium?: string;
  _id?: string;
}

export const PAGE_COUNT: number = 1000;
export const PAGE_OFFSET: number = 0;

export const communicationParams = {
  recipient: 'me',
  medium: 'MAIL',
  status: 'completed',
  _include: ['Communication:sender'],
  _sort: '-sent',
  _count: PAGE_COUNT,
  _getpagesoffset: PAGE_OFFSET
};

export function getParams(params: SearchParams): string {
  return qs.stringify(params, { indices: false });
}

async function fetchResources({
  resourceType,
  apiUrl,
  params,
  id
}: {
  resourceType: string;
  apiUrl: string;
  params?: SearchParams;
  id?: string;
}) {
  const headers = headersBuilder();

  const queryString = params && `?${getParams(params)}`;

  const url = `${apiUrl}/${resourceType}/${id ?? ''}${queryString ?? ''}`;

  // TODO: Use $fhir client for this call.
  const res = await fetch(url, {
    method: 'GET',
    headers
  });

  const resContent = await res.json();

  if (!res.ok) {
    const { status, statusText } = res;
    throw new Error(
      JSON.stringify({
        ...resContent,
        status,
        statusText,
        traceId: res.headers.get('x-b3-traceid')
      })
    );
  }

  logger.info('HTTP', `GET ${url}`, {
    traceId: res.headers.get('x-b3-traceid'),
    resultNumber: resContent.total
  });

  return resContent;
}

export async function fetchCommunications({ apiUrl }: { apiUrl: string }) {
  try {
    const resources = await fetchResources({
      resourceType: 'Communication',
      apiUrl,
      params: communicationParams
    });

    return resources;
  } catch (error) {
    logger.error('HTTP', 'Failed to fetch Communications', error);
    return { entry: [], total: 0 };
  }
}

export async function fetchDocumentReferences({ apiUrl, ids }: { apiUrl: string; ids?: string[] }) {
  try {
    return Promise.all(
      (ids ?? []).map(async id =>
        fetchResources({
          resourceType: 'DocumentReference',
          apiUrl: apiUrl.concat('/fhir'),
          id: getIdFromReference(id)
        })
      )
    );
  } catch (error) {
    logger.error('HTTP', 'Failed to fetch DocumentReferences', error);
  }
}

export async function fetchBinaries({ apiUrl, ids }: { apiUrl: string; ids: string[] }) {
  try {
    const binaries = await Promise.all(
      ids.map(async id => {
        return fetchResources({
          resourceType: 'Binary',
          apiUrl: apiUrl.concat('/fhir'),
          id: getIdFromReference(id)
        });
      })
    );
    return binaries;
  } catch (error) {
    logger.error('HTTP', 'Failed to fetch Binaries', error);
  }
}
