export interface AuthConfig {
  domain: string;
  clientId: string;
  logoutReturnToUrl?: string;
}

export const config = (env: string): AuthConfig => {
  switch (env) {
    case 'production':
      return {
        domain: 'login.lifen.fr',
        clientId: '1WsM34GU3Lk7psm5lzl90uRhF1ZBMpMu'
      };
    case 'post-prod':
      return {
        domain: 'login.public.post-prod.lifen.fr',
        clientId: 'MuQK2lYTfU8rbkdgQGuNKbKI9Ko7OFIM'
      };
    case 'staging':
      return {
        domain: 'login-staging.lifen.fr',
        clientId: 'j2crNG6QMjInjv41kNoJXDp6ieSeprpb',
        logoutReturnToUrl: 'https://my-staging.lifen.fr'
      };
    default:
      return {
        domain: 'login-staging.lifen.fr',
        clientId: 'j2crNG6QMjInjv41kNoJXDp6ieSeprpb'
      };
  }
};
