import { connect } from 'react-redux';

import {
  searchCommunication,
  selectCurrentCommunicationReference
} from '../../store/dashboard/dashboard.action';
import {
  getCurrentCommunicationReference,
  getDashboardLoadingStatus,
  getDashboardTotal,
  getFullCommunicationList
} from '../../store/dashboard/dashboard.selector';
import { getDocumentReferences } from '../../store/entities/selector';
import { State } from '../../store/reducer';
import { ApiDomain } from '../../utils';
import { MessageList } from './MessageList';

const mapStateToProps = (state: State) => {
  return {
    communicationList: getFullCommunicationList(state),
    dashboardLoading: getDashboardLoadingStatus(state),
    dashboardTotal: getDashboardTotal(state),
    currentCommunicationReference: getCurrentCommunicationReference(state),
    documentReferences: Object.keys(getDocumentReferences(state))
  };
};
export default connect(mapStateToProps, {
  fetchCommunications: () => searchCommunication(),
  setCurrentCommunicationReference: (reference: string, apiDomain: ApiDomain) =>
    selectCurrentCommunicationReference({ reference, apiDomain })
})(MessageList);
