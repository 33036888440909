import { createSelector } from '@reduxjs/toolkit';
import { Selector } from 'reselect';
import { Communication } from '../../utils/communications';
import { State } from '../reducer';
import { Entities, EntityObject } from './entities.type';

export const getEntities: Selector<State, Entities> = state => state.entities;

export const getCommunications: Selector<State, EntityObject<Communication>> = createSelector<
  State,
  Entities,
  EntityObject<Communication>
>([getEntities], (entities: Entities) => entities.Communication || {});

export const getPractionners: Selector<State, EntityObject<fhir.Practitioner>> = createSelector<
  State,
  Entities,
  EntityObject<fhir.Practitioner>
>([getEntities], (entities: Entities) => entities.Practitioner || {});

export const getOrganizations: Selector<State, EntityObject<fhir.Organization>> = createSelector<
  State,
  Entities,
  EntityObject<fhir.Organization>
>([getEntities], (entities: Entities) => entities.Organization || {});

export const getDocumentReferences: Selector<
  State,
  EntityObject<fhir.DocumentReference>
> = createSelector<State, Entities, EntityObject<fhir.DocumentReference>>(
  [getEntities],
  (entities: Entities) => entities.DocumentReference || {}
);

export const getBinaries: Selector<State, fhir.Binary[]> = createSelector<
  State,
  Entities,
  fhir.Binary[]
>([getEntities], (entities: Entities) => entities.Binary ?? []);

export const getPdfBinaries: Selector<State, fhir.Binary[]> = createSelector<
  State,
  Entities,
  fhir.Binary[]
>([getEntities], (entities: Entities) => {
  const binaries = entities.Binary ?? [];
  return binaries.filter((binary: fhir.Binary) => binary.contentType === 'application/pdf');
});
