import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { groupBy, keyBy, mapValues, merge } from 'lodash/fp';

import { getReference } from '../../utils/fhir';
import {
  setBinariesRequest,
  setBinariesSuccess,
  setCommunicationSuccess,
  setDocumentReferenceSuccess
} from './action';
import { Entities } from './entities.type';

const INITIAL_STATE: Entities = {
  Communication: {},
  Organization: {},
  Practitioner: {},
  DocumentReference: {},
  Binary: []
};

const setEntities = (state: Entities, action: PayloadAction<fhir.Resource[]>): Entities => {
  const { payload } = action;
  const entitiesByType = groupBy('resourceType')(payload);
  const entities = mapValues((value: fhir.Resource, key: string) => keyBy(getReference)(value))(
    entitiesByType
  );

  return merge(entities)(state);
};

const startGetBinaries = (state: Entities): Entities => {
  return {
    ...state,
    Binary: []
  };
};

const setBinaries = (
  state: Entities,
  action: PayloadAction<{ binaries: fhir.Binary[] }>
): Entities => {
  const {
    payload: { binaries }
  } = action;
  return {
    ...state,
    Binary: binaries.map(binary => {
      return {
        id: binary.id,
        content: binary.content,
        contentType: binary.contentType
      };
    })
  };
};

export const entitiesReducer = createReducer(INITIAL_STATE, {
  [setCommunicationSuccess.type]: setEntities,
  [setBinariesSuccess.type]: setBinaries,
  [setBinariesRequest.type]: startGetBinaries,
  [setDocumentReferenceSuccess.type]: setEntities
});
