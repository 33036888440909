import Bowser from 'bowser';

export function isValidBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.satisfies({
    chrome: '>=69',
    firefox: '>=63',
    safari: '>=12',
    edge: '>=18',
    samsung_internet: '>=10.1',
    opera: '>=56'
  });
}

export function getBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowser();
}
