import { Button, Drawer } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getAuthInfo } from '../../fhir';

import { Communication } from '../../utils/communications';
import { FeatureFlags } from '../../utils/featureFlags';
import { DisclaimerEndOfService } from '../DisclaimerEndOfService/DisclaimerEndOfService';
import { useIsMobile } from './../../utils/hooks';
import { Viewer } from './Viewer';
import ViewerActionHeader from './ViewerActionHeader';
import ViewerLoading from './ViewerLoading';

interface ViewerContainerProps {
  currentCommunication: Communication;
  setCurrentCommunicationId: (id: string) => void;
  binaries: fhir.Binary[];
  pdfBinaries: fhir.Binary[];
  isLoading: boolean;
  featureFlags: FeatureFlags;
}

const BackButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ViewerContainer: React.FC<ViewerContainerProps> = ({
  currentCommunication,
  setCurrentCommunicationId,
  binaries,
  pdfBinaries,
  isLoading,
  featureFlags
}) => {
  const token = getAuthInfo().token;

  const isMobile = useIsMobile();
  const isVisible = !isEmpty(currentCommunication);
  const getDisplayId = (communication: Communication): string | undefined => {
    if (!communication) {
      return;
    }
    const prefix = communication?.apiDomain === 'lifen' ? 'L' : 'R';
    return `${prefix}-${communication?.id}`;
  };

  return !isMobile ? (
    <div style={{ height: '100vh' }}>
      <ViewerActionHeader
        displayId={getDisplayId(currentCommunication) ?? ''}
        fhirReference={`Communication/${currentCommunication?.id}`}
        isDocumentLoading={isLoading}
        binaries={binaries}
        pdfBinaries={pdfBinaries}
        isAnswerButtonAllowed={!!featureFlags['answer-button']}
      />
      <div style={{ height: 'calc(100% - 64px)' }}>
        {!isLoading && token ? (
          <Viewer token={token} resource={currentCommunication} binaries={binaries} />
        ) : (
          <ViewerLoading />
        )}
      </div>
    </div>
  ) : (
    <Drawer
      title="Votre document"
      placement={'bottom'}
      closable={true}
      onClose={() => setCurrentCommunicationId('')}
      closeIcon={<BackButton>Retour</BackButton>}
      visible={isVisible}
      height={'100%'}
      headerStyle={{ boxSizing: 'border-box', height: '55px' }}
      bodyStyle={{ padding: 0, height: 'calc(100% - 55px)' }}
    >
      <DisclaimerEndOfService />
      <ViewerActionHeader
        displayId={getDisplayId(currentCommunication) ?? ''}
        fhirReference={`Communication/${currentCommunication?.id}`}
        isDocumentLoading={isLoading}
        binaries={binaries}
        pdfBinaries={pdfBinaries}
        isAnswerButtonAllowed={!!featureFlags['answer-button']}
      />
      {currentCommunication && token ? (
        <Viewer token={token} resource={currentCommunication} binaries={binaries} />
      ) : null}
    </Drawer>
  );
};
