import * as Sentry from '@sentry/react';
import { Transaction, TransactionContext } from '@sentry/types';

import { version } from '../../package.json';
import { Env, getEnv } from './';

interface SentrySDK {
  init(options: Sentry.BrowserOptions): void;
  setUser(user: Sentry.User): void;
  addBreadcrumb(payload: Sentry.Breadcrumb): void;
  captureEvent(payload: Sentry.Event): void;
  withProfiler(payload: React.ComponentType): React.ComponentType;
  startTransaction(transaction: TransactionContext): Transaction;
}

type opType = 'http';

// TODO: This should probably be an environment variable
export const SENTRY_DSN = 'https://ff229e592bae4f6c8d270525b73f113c@sentry.io/1838484';

export class Monitoring {

  private get initOptions(): Sentry.BrowserOptions {
    return {
      dsn: SENTRY_DSN,
      enabled: this.enabled,
      release: this.release,
      environment: this.environment,
      tracesSampleRate: 0.2
    };
  }
  public reduxEnhancer = Sentry.createReduxEnhancer({
    // It's a pitty but we have no choice in our case for data privacy reasons
    actionTransformer: action => ({ type: action.type }),
    stateTransformer: () => null
  });
  private readonly release: string = version;
  private enabled = false;

  constructor(private readonly sdk: SentrySDK, private readonly environment: Env) {
    this.enabled = environment !== 'dev';
  }

  // Configure the Sentry SDK & identify the user
  // See: https://docs.sentry.io/error-reporting/quickstart#configure-the-sdk
  // See: https://docs.sentry.io/platforms/javascript/#capturing-the-user
  public init(user: Sentry.User): void {
    this.sdk.init(this.initOptions);
    this.sdk.setUser(user);
  }

  public withProfiler(component: React.ComponentType): React.ComponentType {
    return this.sdk.withProfiler(component);
  }

  public addEvent(category: string, message: string, level: string): void {
    this.sdk.addBreadcrumb({ category, message, level: level as Sentry.Severity });
  }

  public trace(name: string, op: opType) {
    const transaction = this.sdk.startTransaction({ name, op });
    const span = transaction.startChild({ op, description: name });

    return {
      setHttpStatus: (status: number) => span.setHttpStatus(status),
      setData: (key: string, value: string) => span.setData(key, value),
      setTag: (key: string, value: string) => span.setTag(key, value),
      finish: () => {
        span.finish();
        transaction.finish();
      }
    };
  }
}

export const monitoring = new Monitoring(Sentry, getEnv(window.location.origin));
