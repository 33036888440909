import { Spin } from 'antd';
import React from 'react';

const ViewerLoading = (): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: '#f6f6f8',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default ViewerLoading;
