import { Viewer as PdfReader } from '@honestica/lifen-viewer';
import React from 'react';
import { getApiUrl } from '../../utils';
import { Communication } from '../../utils/communications';

export const Viewer: React.FC<{
  binaries: fhir.Binary[];
  resource: Communication;
  token: string;
}> = ({ binaries, resource, token }) => {
  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        position: 'relative',
        display: 'block',
        backgroundColor: '#f6f6f8'
      }}
    >
      <PdfReader
        menu
        hideZoom
        hideDownload
        hidePrint
        hideSidebar={binaries.length <= 1}
        resource={resource}
        apiUrl={`${getApiUrl(resource.apiDomain)}/fhir`}
        token={token}
      />
    </div>
  );
};
