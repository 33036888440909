import { createAction } from '@reduxjs/toolkit';

export const setCommunicationSuccess = createAction<fhir.Resource[]>('COMMUNICATION_SET_SUCCESS');
export const setCommunicationFailure = createAction('COMMUNICATION_SET_FAILURE');

export const setBinariesRequest = createAction<fhir.Resource[]>('BINARY_SET_REQUEST');
export const setBinariesSuccess = createAction<{ binaries: fhir.Binary[] }>('BINARY_SET_SUCCESS');
export const setBinariesFailure = createAction<{ err: any }>('BINARY_SET_FAILURE');

export const setDocumentReferenceSuccess = createAction<fhir.DocumentReference[]>(
  'DOCUMENTREFERENCE_SET_SUCCESS'
);
