import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../assets/loadingAnimation.json';

const Splash = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div
      className="lottie-container"
      style={{
        margin: 'auto',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#ff6742',
        transition: 'opacity 4s ease 0s'
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />
      <h3 style={{ color: '#fff' }}>Chargement en cours...</h3>
    </div>
  );
};

export default Splash;
