import { filter, last, split } from 'lodash/fp';
import { isCommunication, isFhirCommunication } from './communications';
import { ApiDomain } from './index';

export function getReferenceString(
  resourceId: string,
  resourceType: string,
  apiDomain?: ApiDomain
) {
  return apiDomain ? `${resourceType}/${apiDomain}/${resourceId}` : `${resourceType}/${resourceId}`;
}

export function getReference(resource: fhir.Resource): string {
  if (isCommunication(resource)) {
    return getReferenceString(resource.id ?? '', resource.resourceType ?? '', resource.apiDomain);
  }
  return getReferenceString(resource.id ?? '', resource.resourceType ?? '');
}

export function getIdFromReference(reference: string): string {
  return last(split('/')(reference || '')) ?? '';
}

const isApiDomainDependentResource = (resource: fhir.Resource): boolean =>
  isFhirCommunication(resource);

export function addApiDomain(resource: fhir.Resource, apiDomain: ApiDomain) {
  return isApiDomainDependentResource(resource) ? { ...resource, apiDomain } : resource;
}
export const addOrUpdateExtension = (
  extensions: fhir.Extension[],
  url: string,
  value: string
): fhir.Extension[] => {
  const newExtension: fhir.Extension = { url, valueString: value };
  // we remove the extension if it exist
  // if extensions is undefined, filteredExtensions will be []
  const filteredExtension: fhir.Extension[] = filter(
    extension => extension.url !== url,
    extensions
  );
  return [...filteredExtension, newExtension];
};
