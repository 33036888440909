import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Communication } from '../../utils/communications';
import { FeatureFlags } from '../../utils/featureFlags';
import { getReference } from '../../utils/fhir';
import {
  searchCommunication,
  searchCommunicationSuccess,
  selectCurrentCommunicationReference,
  setFeatureFlagsRequest,
  toggleCurrentCommunicationLoading
} from './dashboard.action';

export interface Dashboard {
  list: string[];
  loading: boolean;
  total: number;
  currentCommunicationReference?: string;
  currentCommunicationLoading: boolean;
  featureFlags: FeatureFlags;
}

const INITIAL_STATE: Dashboard = {
  list: [],
  loading: true,
  total: 0,
  currentCommunicationLoading: true,
  featureFlags: {}
};

function searchSuccess(
  state: Dashboard,
  action: PayloadAction<{
    communications: Communication[];
    total: number;
  }>
) {
  const { payload } = action;
  state.list = payload.communications.map((communication: Communication) =>
    getReference(communication)
  );
  state.loading = false;
  state.total = payload.total;
  return state;
}

function loading(state: Dashboard, status: boolean) {
  state.loading = status;
}

function setCurrentCommunicationReference(
  state: Dashboard,
  action: PayloadAction<{ reference: string }>
) {
  state.currentCommunicationReference = action.payload.reference;
}

function setCurrentCommunicationLoading(state: Dashboard, action: PayloadAction<boolean>) {
  return {
    ...state,
    currentCommunicationLoading: action.payload
  };
}

function setFeatureFlags(state: Dashboard, action: PayloadAction<FeatureFlags>) {
  return {
    ...state,
    featureFlags: { ...state.featureFlags, ...action.payload }
  };
}

export const dashboardReducer = createReducer(INITIAL_STATE, {
  [searchCommunicationSuccess.type]: searchSuccess,
  [searchCommunication.type]: state => loading(state, true),
  [selectCurrentCommunicationReference.type]: setCurrentCommunicationReference,
  [toggleCurrentCommunicationLoading.type]: setCurrentCommunicationLoading,
  [setFeatureFlagsRequest.type]: setFeatureFlags
});
