import { WarningOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';

export const DisclaimerEndOfService = () => {
  return (
    <Alert
      style={{ border: '2px solid rgb(255, 103, 73)', backgroundColor: 'rgb(255,225,218)' }}
      message={
        <div>
          <WarningOutlined /> <strong>IMPORTANT</strong> : le 31 mars 2024,{' '}
          <strong>votre espace patient Lifen sera supprimé</strong>. L’État français a lancé “Mon
          Espace Santé” en 2022 afin de fournir un espace de confiance sécurisé entre professionnels
          de santé et les citoyens français. Nous vous invitons à télécharger vos documents avant le
          31 mars 2024 et à les stocker dans “Mon Espace Santé” en toute sécurité pour les rendre
          disponibles aux professionnels de santé de votre choix.{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.monespacesante.fr/">
            En savoir plus sur Mon Espace Santé &gt;
          </a>
        </div>
      }
      type="error"
    ></Alert>
  );
};
