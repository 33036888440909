import { DownloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { ViewerUtils } from '@honestica/lifen-viewer';
import { Button, Divider, Layout, Row, Space } from 'antd';
import React, { useState } from 'react';

import { trackMessageReplyAttempt } from '../../utils/tracking';
import { useIsMobile } from './../../utils/hooks';
import AnswerModal from './AnswerModal';

const { downloadFile, printFile } = ViewerUtils;

interface ViewerActionHeaderProps {
  isDocumentLoading: boolean;
  binaries: fhir.Binary[];
  pdfBinaries: fhir.Binary[];
  displayId: string;
  fhirReference: string;
  isAnswerButtonAllowed: boolean;
}

const ViewerActionHeader = ({
  isDocumentLoading,
  binaries,
  pdfBinaries,
  displayId,
  fhirReference,
  isAnswerButtonAllowed
}: ViewerActionHeaderProps): JSX.Element => {
  const [isAnswerModalVisible, setIsAnswerModalVisible] = useState<boolean>(false);
  const isMobile: boolean = useIsMobile();
  const handleAnswerButtonClick = () => {
    setIsAnswerModalVisible(true);
    trackMessageReplyAttempt({ fhirReference, purpose: 'personal' });
  };
  return (
    <>
      <Layout.Header
        style={{
          width: '100%',
          backgroundColor: '#f6f6f8',
          padding: isMobile ? '0 12px' : '0 50px'
        }}
      >
        <Row justify={isMobile ? 'center' : 'end'}>
          {displayId && <span style={{ fontSize: 12 }}>{displayId}</span>}
          <Divider type="vertical" />
          <Space>
            {isAnswerButtonAllowed && (
              <Button
                onClick={handleAnswerButtonClick}
                disabled={isDocumentLoading}
                icon={<RollbackOutlined />}
              >
                Répondre
              </Button>
            )}
            {!isMobile && <Divider type="vertical" />}
            {pdfBinaries.length >= 1 && !isMobile && (
              <Button onClick={() => printFile(binaries)} disabled={isDocumentLoading}>
                Imprimer
              </Button>
            )}
            <Button
              onClick={() => downloadFile(binaries)}
              type="primary"
              disabled={isDocumentLoading}
              icon={isMobile && <DownloadOutlined />}
            >
              {!isMobile && 'Télécharger'}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <AnswerModal visible={isAnswerModalVisible} setIsVisible={setIsAnswerModalVisible} />
    </>
  );
};

export default ViewerActionHeader;
