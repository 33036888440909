import qs from 'qs';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import { Auth0Provider } from './Authentication/Auth0Provider';
import { config as getConfig } from './Authentication/config';
import { App } from './Components/AppModule';
import { getAudience, getEnv, isProd } from './utils';

import '@honestica/lifen-viewer/lib/bundle.css';
import 'antd/dist/antd.css';
import AuthError from './Components/AuthError';
import { BrowserNotSupported } from './Components/BrowserNotSupportedModule';
import { store } from './store';
import { getBrowser, isValidBrowser } from './utils/browser';
import { trackBrowserNotSupported } from './utils/tracking';

const env = getEnv(window.location.origin);
const segmentId = isProd()
  ? 'FKMpvEgw4QLWL49z5gqPzEJPzj8FB17x'
  : 'pqUhld6noPTWb7vlb9eMIr822anYJQeQ';
(window as any).analytics.load(segmentId);

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

const validBrowser = isValidBrowser();
if (!validBrowser) {
  trackBrowserNotSupported(getBrowser());
}
const url = window.location.search.slice(1); // slice to remove the '?' at the beginning
const queryParams = qs.parse(url);
const isUnauthorized = queryParams.error === 'unauthorized';
const authError = queryParams.error_description;

const config = getConfig(env);

ReactDOM.render(
  <Provider store={store}>
    {validBrowser && (
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        logoutReturnToUrl={config.logoutReturnToUrl}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={getAudience()}
        scope="openid profile COMMUNICATION_READ COMMUNICATION_SEARCH COMMUNICATION_PATCH DOCUMENTREFERENCE_SEARCH DOCUMENTREFERENCE_READ BINARY_READ "
      >
        {isUnauthorized ? <AuthError authError={authError as string} /> : <App />}
      </Auth0Provider>
    )}
    {!validBrowser && <BrowserNotSupported />}
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
