import { createSelector } from '@reduxjs/toolkit';
import { get, map, merge, orderBy } from 'lodash/fp';
import { Selector } from 'reselect';

import { Communication } from '../../utils/communications';
import { FeatureFlags } from '../../utils/featureFlags';
import { getCommunications, getOrganizations, getPractionners } from '../entities/selector';
import { State } from '../reducer';
import { Dashboard } from './dashboard.reducer';
import { EnhancedCommunication } from './dashboard.type';

export const getDashboard: Selector<State, Dashboard> = state => state.dashboard;

export const getDashboardLoadingStatus: Selector<State, boolean> = createSelector<
  State,
  Dashboard,
  boolean
>([getDashboard], (dashboard: Dashboard) => dashboard.loading);

export const getDashboardTotal: Selector<State, number> = createSelector<State, Dashboard, number>(
  [getDashboard],
  (dashboard: Dashboard) => dashboard.total
);

export const getCurrentCommunicationReference: Selector<State, string> = createSelector<
  State,
  Dashboard,
  string
>([getDashboard], (dashboard: Dashboard) => dashboard.currentCommunicationReference ?? '');

export const getCurrentCommunication: Selector<State, Communication> = createSelector(
  [getCurrentCommunicationReference, getCommunications],
  (currentCommunicationReference, communications) => communications[currentCommunicationReference]
);

export const getCommunicationReferences: Selector<State, string[]> = createSelector<
  State,
  Dashboard,
  string[]
>([getDashboard], (dashboard: Dashboard) => dashboard.list || []);

export const getCurrentCommunicationLoading: Selector<State, boolean> = createSelector<
  State,
  Dashboard,
  boolean
>([getDashboard], (dashboard: Dashboard) => dashboard.currentCommunicationLoading);

export const getFeatureFlags: Selector<State, FeatureFlags> = createSelector<
  State,
  Dashboard,
  FeatureFlags
>([getDashboard], (dashboard: Dashboard) => dashboard.featureFlags);

export const getFullCommunicationList: Selector<State, EnhancedCommunication[]> = createSelector(
  [getCommunicationReferences, getCommunications, getPractionners, getOrganizations],
  (communicationReferences, communications, practionners, organizations) => {
    const full = map((crReference: string) => {
      const communication = communications[crReference];
      const sender =
        practionners[get('sender.reference')(communication)] ||
        organizations[get('sender.reference')(communication)];

      return merge({ sender })(communication);
    })(communicationReferences);
    const sortedByDate = orderBy(({ sent }) => sent || '', 'desc', full);
    return sortedByDate;
  }
);
