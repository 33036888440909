import { Avatar, List, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { FullPageError } from '../FullPageErrorModule';
import { BrowserInfo, validBrowserList } from './browserNotSupported.constant';

const standardMargin = 16;

const TextContainer = styled(Row)`
  font-size: '16px';
`;

const TextContainerWithMargin = styled(TextContainer)`
  margin-top: ${standardMargin}px;
`;

const BrowserList = styled(List)`
  width: 100%;
` as typeof List;

const BrowserItem = styled(List.Item)`
  cursor: pointer;
  padding: 32px;
  box-sizing: border-box;
  &:hover {
    background-color: #f6f6f6;
  }
`;

const goToDownload = (url: string) => {
  window.open(url, '_blank');
};

export function BrowserNotSupported() {
  return (
    <FullPageError title="Votre navigateur n'est pas supporté">
      <TextContainer>
        <p>
          Nous vous recommandons de réessayer depuis l'un des navigateurs suivants, dans leur
          version <b>la plus récente possible :</b>
        </p>
      </TextContainer>
      <Row>
        <BrowserList<BrowserInfo>
          itemLayout="horizontal"
          dataSource={validBrowserList}
          size={'large'}
          split={false}
          renderItem={(item: BrowserInfo) => (
            <BrowserItem onClick={() => goToDownload(item.url)}>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={item.name}
                description={item.description}
              />
            </BrowserItem>
          )}
        />
      </Row>
      <TextContainerWithMargin>
        <p>
          Si vous possédez déjà l'un de ces navigateurs, essayez de le mettre à jour, ou
          connectez-vous depuis un autre navigateur
        </p>
      </TextContainerWithMargin>
    </FullPageError>
  );
}
