import { filter } from 'lodash/fp';

export type Env = 'octopus' | 'dev' | 'staging' | 'post-prod' | 'production';
export type ApiDomain = 'lifen' | 'rgds';
export interface ApiConfig {
  apiUrl: string;
  apiDomain: ApiDomain;
}

export function getEnv(origin: string): Env {
  if (
    (window as any).Cypress &&
    (origin.includes('localhost') || origin.includes('staging') || origin.includes('review'))
  ) {
    return 'octopus';
  }

  if (origin.includes('localhost')) {
    return 'dev';
  }

  if (origin.includes('staging') || origin.includes('review')) {
    return 'staging';
  }

  if (origin.includes('post-prod')) {
    return 'post-prod';
  }

  return 'production';
}

export function isProd(): boolean {
  return getEnv(window.location.origin) === 'production';
}

export const WORKSPACES_URL = 'http://lifen.fr/workspaces';
export const USERDATA_URL = 'http://lifen.fr/userData';
export function getApiConfigs(user: any) {
  // TODO créer un type user et/ou un type workspace? puis la function isPersonalWorkspace ou au moins les constante WORKSPACE_PERSONAL
  // TODO move this elsewhere?
  const workspaces = user[WORKSPACES_URL];
  const personalWorkspaces = filter(workspace => workspace.purpose === 'personal', workspaces);
  return personalWorkspaces.reduce((acc: ApiConfig[], workspace) => {
    if (!acc.flatMap(apiConfig => apiConfig.apiDomain).includes(workspace.apiDomain)) {
      acc.push({ apiUrl: `${workspace.apiUrl}/fhir`, apiDomain: workspace.apiDomain });
    }
    return acc;
  }, []);
}

export function getApiUrl(apiDomain: ApiDomain) {
  return apiDomain === 'lifen' ? getLifenApiUrl() : getRgdsApiUrl();
}

export function getLifenApiUrl() {
  const env: Env = getEnv(window.location.origin);
  const apis: Record<Env, string> = {
    production: 'https://api.lifen.fr',
    staging: 'https://fhir-api.private.staging.lifen.fr',
    'post-prod': 'https://fhir-api.public.post-prod.lifen.fr',
    dev: 'https://fhir-api.private.staging.lifen.fr',
    octopus: 'https://fhir-api.private.staging.lifen.fr'
  };
  return apis[env] || apis.staging;
}

export function getLifenAppUrl() {
  const env: Env = getEnv(window.location.origin);

  const urls: Record<Env, string> = {
    dev: 'https://api.private.staging.lifen.fr',
    'post-prod': 'https://api.public.post-prod.lifen.fr',
    production: 'https://api.lifen.fr',
    staging: 'https://api.private.staging.lifen.fr',
    octopus: 'https://api.private.staging.lifen.fr'
  };

  return urls[env] || urls.staging;
}

export function getRgdsApiUrl() {
  return isProd()
    ? 'https://api.lifen.ramsaygds.fr'
    : 'https://fhir-api.private.staging.lifen.ramsaygds.fr';
}

export function getAudience() {
  const env = getEnv(window.location.origin);
  const audiences: { [key in Env]: string } = {
    dev: 'https://api-rc.internal.k8s-azure.lifen.fr',
    staging: 'https://api-rc.internal.k8s-azure.lifen.fr',
    production: 'https://api.lifen.fr',
    'post-prod': 'post-prod-apis',
    octopus: 'https://api-rc.internal.k8s-azure.lifen.fr'
  };
  return audiences[env];
}
