import { ApiDomain } from './index';

export interface Communication extends fhir.Communication {
  apiDomain: ApiDomain;
}

export const isFhirCommunication = (resource: fhir.Resource): resource is fhir.Communication =>
  resource.resourceType === 'Communication';

export const isCommunication = (resource: any): resource is Communication =>
  isFhirCommunication({ ...resource }) && typeof resource.apiDomain === 'string';
