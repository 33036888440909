import { Divider, List, Skeleton } from 'antd';
import { isEmpty } from 'lodash/fp';
import React, { FC, useEffect, useState } from 'react';

import { useAuth0 } from '../../Authentication/Auth0Provider';
import { setAuthInfo } from '../../fhir';
import {
  displayHoursIfTodayOtherwiseFullDate,
  getQualificationForSender,
  getSenderName
} from '../../selectors';
import { EnhancedCommunication } from '../../store/dashboard/dashboard.type';
import { ApiDomain } from '../../utils';
import { Communication } from '../../utils/communications';
import { getReference } from '../../utils/fhir';
import { useIsMobile } from '../../utils/hooks';
import { logger } from '../../utils/logger';

interface MessageListProps {
  communicationList: EnhancedCommunication[];
  currentCommunicationReference: string;
  dashboardLoading: boolean;
  dashboardTotal: number;
  setCurrentCommunicationReference: (reference: string, apiDomain: ApiDomain) => void;
  fetchCommunications: (searchParams?: { page?: number }) => void;
  documentReferences: string[];
}

export const DEFAULT_PAGE_COUNT = 20;
export const MessageList: FC<MessageListProps> = props => {
  const isMobile = useIsMobile();
  const { isAuthenticated, loading, getTokenSilently, user }: any = useAuth0();
  const {
    dashboardLoading,
    dashboardTotal,
    communicationList,
    fetchCommunications,
    setCurrentCommunicationReference,
    currentCommunicationReference,
    documentReferences
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const onClickMessage = (communication: Communication) => {
    const comReference = getReference(communication);
    logger.info('UI', `Switching to ${comReference}`);
    setCurrentCommunicationReference(comReference, communication.apiDomain);
  };

  useEffect(() => {
    if (loading || !isAuthenticated) {
      return;
    }
    const initialFetchCommunications = async () => {
      const token = await getTokenSilently();
      setAuthInfo(token, user);
      fetchCommunications();
    };
    initialFetchCommunications();
  }, [loading, getTokenSilently, isAuthenticated, user, fetchCommunications]);

  useEffect(() => {
    if (isEmpty(communicationList) || documentReferences.length > 0) {
      return;
    }
    const firstDocIndex = (currentPage - 1) * DEFAULT_PAGE_COUNT;
    const communication = communicationList[firstDocIndex];

    setCurrentCommunicationReference(getReference(communication), communication.apiDomain);
  }, [
    communicationList,
    isMobile,
    setCurrentCommunicationReference,
    currentPage,
    documentReferences.length
  ]);
  return (
    <div
      style={{
        height: '100vh',
        maxHeight: '100vh',
        borderLeft: '1px solid #e8e8e8',
        borderRight: '1px solid #e8e8e8',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          padding: '20px'
        }}
      >
        {' '}
        <h1>Mes documents</h1>
      </div>

      {dashboardLoading ? (
        <div
          style={{
            padding: '20px'
          }}
        >
          <Skeleton active paragraph={{ rows: 1 }} />
          <Divider />
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
      ) : (
        <List
          style={{ overflowY: 'auto' }}
          locale={{ emptyText: 'Aucun document' }}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: DEFAULT_PAGE_COUNT,
            total: dashboardTotal,
            position: 'top',
            size: 'small',
            style: { marginBottom: '16px' },
            onChange: page => {
              setCurrentPage(page);
            }
          }}
          dataSource={communicationList}
          renderItem={(communication: EnhancedCommunication) => (
            <List.Item
              key={communication.id}
              onClick={() => onClickMessage(communication)}
              style={{
                padding: '20px',
                cursor: 'pointer',
                backgroundColor:
                  getReference(communication) === currentCommunicationReference ? '#f6f6f8' : '#fff'
              }}
            >
              <List.Item.Meta
                title={<div>{getSenderName(communication.sender)}</div>}
                description={
                  <div>
                    {getQualificationForSender(communication.sender) || 'Établissement de santé'}
                  </div>
                }
              />
              <div
                style={{
                  fontWeight: 300,
                  fontSize: '12px'
                }}
              >
                {communication.sent &&
                  displayHoursIfTodayOtherwiseFullDate(new Date(communication.sent))}
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
