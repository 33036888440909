import React from 'react';
import { useAuth0 } from '../Authentication/Auth0Provider';

import { Button, Col, Row } from 'antd';
import { FullPageError } from './FullPageErrorModule';

import { isProd } from '../utils';

export default ({ authError }: { authError: string }) => {
  const { logout }: any = useAuth0();
  const lifenAppUrl = isProd() ? 'https://app.lifen.fr' : 'https://app-staging.lifen.fr';

  const getErrorType = () => {
    switch (authError) {
      case 'Error 85 - LIFEN-PATIENT':
        return 'professionalPurpose';
      case 'Votre compte est désactivé. Veuillez contacter votre administrateur.':
        return 'deactivatedUser';
      default:
        return 'fallback';
    }
  };

  const errorMessage = {
    deactivatedUser: {
      title: 'Votre compte a été bloqué',
      body: `Pour des raisons de sécurité, suite à un nombre trop important de 
      tentatives de connexion, nous avons verrouillé l'accès à votre compte. 
      Merci de prendre contact avec notre support technique à l'adresse 
      support-patient@lifen.fr pour retrouver l'accès à votre compte.`,
      actions: []
    },
    professionalPurpose: {
      title: 'Cet espace est réservé aux patients',
      body: `Il semble que vous tentiez d'accéder à l'espace patient avec un compte professionnel. Si
        vous souhaitez accéder à votre espace patient, connectez-vous avec votre compte personnel.
        Sinon, rendez-vous sur l'espace professionnel`,
      actions: [
        {
          text: 'Accéder à mon espace professionnel',
          onClick: () => window.location.replace(lifenAppUrl)
        },
        { text: 'Me connecter avec un compte personnel', onClick: () => logout() }
      ]
    },
    fallback: {
      title: `Une erreur s'est produite`,
      body: `Nous ne sommes pas en mesure de vous authentifier. Veuillez prendre contact avec le support technique à l'adresse support-patient@lifen.fr`,
      actions: []
    }
  };

  const { body, title, actions = [] } = errorMessage[getErrorType()];
  return (
    <FullPageError title={title}>
      <p>{body}</p>
      {actions.length > 0 && (
        <>
          <br />
          <Row gutter={6}>
            {actions.map((action, index) => {
              return (
                <Col key={index}>
                  <Button type={index === 0 ? 'primary' : 'default'} onClick={action.onClick}>
                    {action.text}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </FullPageError>
  );
};
