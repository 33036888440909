import { connect } from 'react-redux';
import { setError } from '../../store/error/error.action';
import { State } from '../../store/reducer';
import { ErrorAlert } from './ErrorAlert';

const mapStateToProps = (state: State) => {
  return {
    error: state.error
  };
};
export default connect(mapStateToProps, { setError })(ErrorAlert);
