import { version } from '../../package.json';
import { getEnv, getLifenAppUrl } from './';
import { monitoring } from './monitoring';

type EnqueueLog = Pick<ClientLog, 'source' | 'message' | 'level'>;

interface LoggerOptions {
  environment: LOG_ENVIRONMENT;
  version: Log['version'];
  beforeEnqueue(log: EnqueueLog): void;
}

enum LOG_LEVEL {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

enum LOG_ENVIRONMENT {
  DEV = 'dev',
  TEST = 'test',
  STAGING = 'staging',
  PRODUCTION = 'production',
  POST_PROD = 'post-prod'
}

type LogAppName = 'core-apps-server' | 'core-apps-client' | 'lifen-patient';

interface Log {
  readonly level: LOG_LEVEL;
  readonly environment: LOG_ENVIRONMENT;
  readonly version: string;
  readonly message: string;
  readonly appname: LogAppName;
}

interface ClientLog extends Log {
  readonly host: string;
  readonly path: string;
  readonly source: 'HTTP' | 'LOGIC' | 'UI';
  readonly userId: string;
  readonly sessionId: string;
}

const sessionId = Math.random()
  .toString(36)
  .substring(2, 15);

export class Logger {
  private get shouldFlush(): boolean {
    return this.queue.length >= this.flushAt;
  }

  private get canSend(): boolean {
    return this.initialized && this.queue.length > 0;
  }
  private readonly maxRetry: number = 3;
  private readonly retryInterval: number = 30000;
  private readonly flushAt: number = 20;
  private readonly flushInterval: number = 10000;

  private flushTimeout: NodeJS.Timeout | null = null;
  private enabled = false;
  private consoleEnabled = false;
  private queue: EnqueueLog[] = [];
  private initialized = false;
  private userId: ClientLog['userId'] = '';
  private token = '';

  constructor(private readonly options: LoggerOptions) {
    this.enabled = this.options.environment !== LOG_ENVIRONMENT.DEV;
    this.consoleEnabled =
      this.options.environment !== LOG_ENVIRONMENT.PRODUCTION && process.env.NODE_ENV !== 'test';
  }

  public flush(): void {
    if (this.canSend) {
      this.send(this.format(this.queue));
      this.queue = [];
    }
  }

  public init(userId: ClientLog['userId'], token: string): void {
    this.userId = userId;
    this.token = `Bearer ${token}`;
    this.initialized = true;
    this.flush();
  }

  private get url(): string {
    return `${getLifenAppUrl()}/private/api/v1/logs`;
  }

  public debug(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: Record<string, unknown> = {}
  ): void {
    this.log({ source, message, level: LOG_LEVEL.DEBUG, ...options });
  }

  public info(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: Record<string, unknown> = {}
  ): void {
    this.log({ source, message, level: LOG_LEVEL.INFO, ...options });
  }

  public warn(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: Record<string, unknown> = {}
  ): void {
    this.log({ source, message, level: LOG_LEVEL.WARN, ...options });
  }

  public error(
    source: ClientLog['source'],
    message: ClientLog['message'],
    error: unknown = {}
  ): void {
    this.log({
      source,
      message,
      level: LOG_LEVEL.ERROR,
      ...{ error: error instanceof Error ? error.message : JSON.stringify(error as any) }
    });
  }

  private getRequestInit(logs: Log[]): RequestInit {
    return {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(logs)
    };
  }

  private async send(logs: Log[], attempt = 1): Promise<void> {
    try {
      await fetch(this.url, this.getRequestInit(logs));
    } catch (err) {
      if (attempt < this.maxRetry) {
        setTimeout(() => this.send(logs, attempt + 1), this.retryInterval);
      }
    }
  }

  private format(logs: EnqueueLog[]): ClientLog[] {
    return logs.map(log => ({
      ...log,
      message: `[PATIENT PORTAL] ${log.message}`,
      appname: 'lifen-patient',
      environment: this.options.environment,
      version: this.options.version,
      host: window.location.host,
      path: window.location.pathname,
      userId: this.userId,
      sessionId
    }));
  }

  private enqueue(log: EnqueueLog): void {
    this.queue.push(log);

    if (this.flushTimeout !== null) {
      clearTimeout(this.flushTimeout);
    }

    if (this.shouldFlush) {
      this.flush();
    } else {
      this.flushTimeout = setTimeout(this.flush.bind(this), this.flushInterval);
    }
  }

  private log(log: EnqueueLog): void {
    if (this.enabled) {
      this.enqueue(log);
    }
    if (this.consoleEnabled) {
      console[log.level](log.message, JSON.stringify(log, null, '  '));
    }
  }
}

export const logger = new Logger({
  environment: getEnv(window.location.origin) as LOG_ENVIRONMENT,
  version,
  beforeEnqueue: (log: EnqueueLog) => {
    monitoring.addEvent(log.source, log.message, log.level);
  }
});
