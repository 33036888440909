import { Dashboard, dashboardReducer } from './dashboard/dashboard.reducer';
import { Entities } from './entities/entities.type';
import { entitiesReducer } from './entities/reducer';
import { errorReducer } from './error/error.reducer';

export interface State {
  entities: Entities;
  dashboard: Dashboard;
  error: Error;
}
export const rootReducer = {
  entities: entitiesReducer,
  dashboard: dashboardReducer,
  error: errorReducer
};
