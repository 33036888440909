import { createAction } from '@reduxjs/toolkit';
import { ApiDomain } from '../../utils';
import { Communication } from '../../utils/communications';
import { FeatureFlags } from '../../utils/featureFlags';

export const searchCommunication = createAction<void>('DASHBOARD_SEARCH_CR');

export const searchCommunicationSuccess = createAction<{
  communications: Communication[];
  total: number;
}>('DASHBOARD_SEARCH_CR_SUCCESS');

export const selectCurrentCommunicationReference = createAction<{
  reference: string;
  apiDomain?: ApiDomain;
}>('DASHBOARD_SELECT_CURRENT_COMMUNICATION_REFERENCE');

export const toggleCurrentCommunicationLoading = createAction<boolean>(
  'DASHBOARD_TOGGLE_CURRENT_COMMUNICATION_LOADING'
);

export const setFeatureFlagsRequest = createAction<FeatureFlags>('DASHBOARD_SET_FEATURE_FLAGS');
