import { isUndefined } from 'lodash/fp';

// user is typed as any because it comes from auth0 and we don't control it
export function identify(user: any) {
  const analytics = (window as any).analytics;
  if (!analytics) {
    return;
  }
  const uuid = user['http://lifen.fr/userData'].userUuid;
  (window as any).analytics.identify(uuid, {
    role: 'patient'
  });
}

export function track(eventName: string, properties: any) {
  const analytics = (window as any).analytics;
  if (!analytics) {
    return;
  }

  analytics.track(eventName, properties);
}

const MESSAGE_OPENED = 'Message Opened';
export function trackMessageOpened({
  communication,
  senderName,
  documentLoincType
}: {
  communication: fhir.Communication | undefined;
  senderName: string;
  documentLoincType: string;
}) {
  if (isUndefined(communication?.id)) {
    return;
  }

  track(MESSAGE_OPENED, {
    communicationId: communication?.id,
    senderFhirReference: communication?.sender?.reference,
    documentLoincType,
    senderName
  });
}

const BROWSER_NOT_SUPPORTED = 'User Browser Not Supported';
export function trackBrowserNotSupported(browserDetails: Bowser.Parser.BrowserDetails) {
  track(BROWSER_NOT_SUPPORTED, {
    browserVersion: browserDetails.version,
    browserName: browserDetails.name
  });
}

const MESSAGE_REPLY_ATTEMPTED = 'Message Reply Attempted';
export function trackMessageReplyAttempt(properties: {
  fhirReference: string;
  purpose: 'personal' | 'professional';
}) {
  const { fhirReference, purpose } = properties;
  track(MESSAGE_REPLY_ATTEMPTED, {
    fhirReference,
    purpose
  });
}
