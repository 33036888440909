import chrome from './BrowserImages/chrome.png';
import edge from './BrowserImages/edge.png';
import firefox from './BrowserImages/firefox.png';
import safari from './BrowserImages/safari.png';

export interface BrowserInfo {
  name: string;
  description: string;
  avatar: string;
  url: string;
}

export const validBrowserList: BrowserInfo[] = [
  {
    name: 'Google Chrome',
    description: 'version supérieure à 69',
    avatar: chrome,
    url: 'https://www.google.com/chrome/'
  },
  {
    name: 'Mozilla Firefox',
    description: 'version supérieure à 63',
    avatar: firefox,
    url: 'https://www.mozilla.org/fr/firefox/new/'
  },
  {
    name: 'Safari',
    description: 'version supérieure à 12',
    avatar: safari,
    url: 'https://support.apple.com/downloads#safari'
  },
  {
    name: 'Microsoft Edge',
    description: 'version supérieure à 18',
    avatar: edge,
    url: 'https://www.microsoft.com/en-us/windows/microsoft-edge'
  }
];
