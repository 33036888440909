import { capitalize, get, isEmpty, isNil, isUndefined, map, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { Sender } from './store/entities/entities.type';

export function getFullName(resource: fhir.Organization | fhir.Practitioner): string {
  if (get(resource, 'resourceType') === 'Organization') {
    return capitalize(get(resource as fhir.Organization, 'name', ''));
  }

  if (get(resource, 'resourceType') === 'Practitioner' && !isEmpty(resource.name)) {
    const name = (resource as any).name[0];
    return `${name.given ? capitalize(name.given[0]) : ''} ${capitalize(name.family)}`;
  }

  if (get(resource, 'resourceType') === 'Patient' && !isEmpty(resource.name)) {
    const name = (resource as any).name[0];

    const lastName = ((resource.name as fhir.HumanName[]) ?? []).find(
      o => (o.use && o.use === 'official') || isUndefined(o.use)
    )?.family;

    return `${capitalize(lastName)} ${name.given ? capitalize(name.given[0]) : ''}`;
  }
  return '';
}

export function displayHoursIfTodayOtherwiseFullDate(date: Date) {
  return moment(date).isSame(new Date(), 'day')
    ? moment(date).format('HH:mm')
    : moment(date).format('DD/MM/YYYY');
}

export function getQualificationFromDoctor(practitioner: fhir.Practitioner): string {
  if (isNil(practitioner)) {
    return '';
  }
  const qualification = sortBy(practitioner.qualification, q =>
    get(q, 'code.coding[0].code') === '10' ? -1 : 1
  );
  const doctorCode = get(qualification, '[0].code.coding[0].code', '');
  const firstQualification = get(qualification, '[0].code.text', '');
  const secondQualification = get(qualification, '[1].code.text', '');
  if (doctorCode === '10') {
    return `${secondQualification ? secondQualification : firstQualification}`;
  } else if (doctorCode === '') {
    return '';
  } else {
    return `${firstQualification}`;
  }
}

export function getPrefixForHealthProfessionals(resource: fhir.DomainResource): string {
  const doctorCodes = map(get(resource, 'qualification'), qualif =>
    get(qualif, 'code.coding[0].code')
  );
  if (get(resource, 'resourceType') === 'Practitioner' && doctorCodes.includes('10')) {
    return 'Dr ';
  } else {
    return '';
  }
}

export function getSenderName(sender: Sender): string {
  if (isUndefined(sender)) {
    return 'N/A';
  }
  const name = getFullName(sender);
  const qualification = getPrefixForHealthProfessionals(sender);
  return `${qualification} ${name}`.trim();
}

export function getQualificationForSender(sender: Sender): string {
  if (get(sender, 'ResourceType') === 'Organization') {
    return '';
  }
  return getQualificationFromDoctor(sender as fhir.Practitioner);
}
