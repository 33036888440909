import { Col, Layout, Row } from 'antd';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useEffect } from 'react';

import { useAuth0 } from '../../Authentication/Auth0Provider';
import { logger } from '../../utils/logger';
import { monitoring } from '../../utils/monitoring';
import { identify } from '../../utils/tracking';
import { DisclaimerEndOfService } from '../DisclaimerEndOfService/DisclaimerEndOfService';
import { ErrorAlert } from '../ErrorModule';
import { Header } from '../HeaderModule';
import { MessageList } from '../MessageListModule';
import Splash from '../SplashScreen';
import { ViewerContainer } from '../ViewerModule';

const { Content } = Layout;

const App = (): JSX.Element => {
  const {
    isAuthenticated,
    loading,
    loginWithRedirect,
    user,
    logout,
    getTokenSilently
  } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }
    const uuid = user['http://lifen.fr/userData'].userUuid;

    async function initLogger() {
      const token = await getTokenSilently();
      logger.init(uuid, token);
    }

    identify(user);
    monitoring.init({ email: user.email, id: uuid, username: 'PATIENT' });
    initLogger();

    logger.info('UI', 'User successfully logged in');

    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  useEffect(() => {
    const parsedParams = queryString.parse(window.location.search);
    // we decode encoded email because we have emails with the forbidden + symbol in urls
    const options = !isEmpty(parsedParams.email)
      ? {
          email: decodeURIComponent(parsedParams.email as string),
          appClient: 'patient'
        }
      : { appClient: 'patient' };
    if (!isAuthenticated && !loading) {
      loginWithRedirect(options);
    }
    // eslint-disable-next-line
  }, [loading]);
  if (!isAuthenticated || loading) {
    return <Splash />;
  }
  return (
    <Layout style={{ height: '100vh' }}>
      <ErrorAlert />
      <DisclaimerEndOfService />
      <Header logout={logout} userEmail={user?.email ?? ''} />
      <Content>
        <Row style={{ backgroundColor: '#fff', width: '100%' }}>
          <Col xs={24} sm={8} lg={8} xl={8}>
            <MessageList />
          </Col>
          <Col span={16}>
            <ViewerContainer />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default App;
