// src/react-auth0-wrapper.js
import createAuth0Client, { User } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import React, { useContext, useEffect, useState } from 'react';
import { logger } from '../utils/logger';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0ContextHookReturnType {
  isAuthenticated: boolean | undefined;
  loading: boolean;
  popupOpen: boolean;
  user: User | undefined;
  getIdTokenClaims(...args: any): void;
  getTokenSilently(...args: any): Promise<any> | undefined;
  getTokenWithPopup(...args: any): void;
  handleRedirectCallback(...args: any): void;
  loginWithPopup(...args: any): void;
  loginWithRedirect(...args: any): void;
  logout(...args: any): void;
}

export const Auth0Context = React.createContext({} as Auth0ContextHookReturnType);
export const useAuth0 = () => useContext<Auth0ContextHookReturnType>(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  leeway = 3600,
  logoutReturnToUrl,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({ leeway, ...initOptions });
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuth = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuth);

      if (isAuth) {
        const authenticatedUser = await auth0FromHook.getUser();
        setUser(authenticatedUser);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    if (!auth0Client) {
      return;
    }
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      logger.error('LOGIC', 'Failed to login with popup', error);
    } finally {
      setPopupOpen(false);
    }
    const authenticatedUser = await auth0Client.getUser();

    setUser(authenticatedUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    if (!auth0Client) {
      return;
    }

    await auth0Client.handleRedirectCallback();
    const authenticatedUser = await auth0Client.getUser();

    setLoading(false);
    setIsAuthenticated(true);
    setUser(authenticatedUser);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client?.getTokenWithPopup(...p),
        logout: () => {
          auth0Client?.logout(!!logoutReturnToUrl ? { returnTo: logoutReturnToUrl } : {});
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
