import { Button, Layout, Typography } from 'antd';
import React from 'react';

import { useIsMobile } from '../../utils/hooks';
import { logger } from '../../utils/logger';

const { Header: AntHeader } = Layout;

export const Header = ({ userEmail, logout }: { userEmail: string; logout: () => void }) => {
  const isMobile = useIsMobile();
  const onLogout = () => {
    logger.info('UI', 'About to logout');
    logout();
  };
  return (
    <AntHeader
      style={{
        backgroundColor: '#fff',
        borderBottom: '1px solid #eee',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <img
        src={process.env.PUBLIC_URL + '/logo_reversed720.jpg'}
        alt="logo"
        width="50px"
        height="50px"
        style={{ borderRadius: '5px', marginTop: '5px' }}
      ></img>
      <div>
        {!isMobile && <Typography.Text>{userEmail}</Typography.Text>}
        <Button type="link" onClick={onLogout}>
          Se déconnecter
        </Button>
      </div>
    </AntHeader>
  );
};
