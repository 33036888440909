import { all, call, put, select } from 'redux-saga/effects';

import {
  fetchBinaries,
  fetchCommunications,
  fetchDocumentReferences,
  getAuthInfo
} from '../../fhir';
import { getSenderName } from '../../selectors';
import { ApiConfig, ApiDomain, getApiConfigs, getApiUrl } from '../../utils';
import { Communication } from '../../utils/communications';
import { addApiDomain, getIdFromReference } from '../../utils/fhir';
import { logger } from '../../utils/logger';
import { trackMessageOpened } from '../../utils/tracking';
import { toggleCurrentCommunicationLoading } from '../dashboard/dashboard.action';
import { getCurrentCommunication } from '../dashboard/dashboard.selector';
import { setError } from '../error/error.action';
import { setCommunicationSuccess, setDocumentReferenceSuccess } from './action';
import { Entities } from './entities.type';
import { getEntities } from './selector';

export function* fetchCommunicationSaga() {
  const { user } = getAuthInfo(); // TODO get it from store
  try {
    const apiConfigs: ApiConfig[] = getApiConfigs(user);
    const calls = apiConfigs.map(apiConfig =>
      call(fetchCommunications, { apiUrl: apiConfig.apiUrl })
    );

    const resultsByDomain: fhir.Bundle[] = yield all(calls);

    const entities: fhir.Resource[] = [];
    let total: number = 0;

    for (let index = 0; index < resultsByDomain.length; index++) {
      const result: fhir.Bundle = resultsByDomain[index];
      const apiDomain: ApiDomain = apiConfigs[index].apiDomain;
      const entries: fhir.BundleEntry[] = result.entry || [];

      const entitiesForDomain: fhir.Resource[] = entries.map((entry: fhir.BundleEntry) => {
        return addApiDomain(entry.resource as fhir.Resource, apiDomain);
      });
      if (result.total) {
        total += result.total;
      }
      entities.push(...entitiesForDomain);
    }

    yield put(setCommunicationSuccess(entities));
    return { entities, total };
  } catch (error) {
    logger.error('LOGIC', 'Failed to fetch Communications', error);
    yield error instanceof Error && put(setError({ name: error.name, message: error.message }));

    return { entities: [], total: 0 };
  }
}

function* trackingMessageOpen(documentLoincType: string) {
  const communication: Communication = yield select(getCurrentCommunication);
  const entities: Entities = yield select(getEntities);
  const sender =
    entities[communication.sender?.reference?.split('/')[0] as 'Practitioner' | 'Organization'][
      communication.sender?.reference ?? ''
    ];
  const senderName = getSenderName(sender);

  yield call(trackMessageOpened, { communication, senderName, documentLoincType });
}

export function* fetchBinariesSaga() {
  yield put(toggleCurrentCommunicationLoading(true));
  const communication: Communication = yield select(getCurrentCommunication);
  const docRefIds = (communication.payload ?? []).map(
    payload => payload.contentReference?.reference ?? ''
  );
  const apiUrl = getApiUrl(communication.apiDomain);

  try {
    const docRefs: fhir.DocumentReference[] = yield call<typeof fetchDocumentReferences>(
      fetchDocumentReferences,
      {
        apiUrl,
        ids: docRefIds
      }
    );

    // Reorder entries
    const orderedDocRefs: fhir.DocumentReference[] = docRefIds.map(
      id => docRefs.find(docRef => docRef.id === getIdFromReference(id)) as fhir.DocumentReference
    );

    yield put(setDocumentReferenceSuccess(orderedDocRefs));

    const binaryIds: string[] = [];
    orderedDocRefs.map(docRef =>
      docRef.content.forEach(content => {
        binaryIds.push(content.attachment.url ?? '');
      })
    );

    yield call<typeof trackingMessageOpen>(
      trackingMessageOpen,
      orderedDocRefs[0].type.coding?.[0]?.code ?? ''
    );

    const binaries: fhir.Binary[] = yield call<typeof fetchBinaries>(fetchBinaries, {
      apiUrl,
      ids: binaryIds
    });
    return binaries;
  } catch (error) {
    logger.error('LOGIC', 'Failed to fetch Binaries', error);
    yield error instanceof Error && put(setError({ name: error.name, message: error.message }));

    return { entities: [], total: 0 };
  }
}
