import { PayloadAction } from '@reduxjs/toolkit';
import { filter, isUndefined, orderBy } from 'lodash/fp';
import moment from 'moment-timezone';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { $fhir, getAuthInfo } from '../../fhir';
import { ApiDomain } from '../../utils';
import { Communication, isCommunication } from '../../utils/communications';
import { addOrUpdateExtension } from '../../utils/fhir';
import { logger } from '../../utils/logger';
import { setBinariesFailure, setBinariesRequest, setBinariesSuccess } from '../entities/action';
import { EntityObject } from '../entities/entities.type';
import { getCommunications } from '../entities/selector';
import { fetchBinariesSaga, fetchCommunicationSaga } from './../entities/sagas';
import {
  searchCommunication,
  searchCommunicationSuccess,
  selectCurrentCommunicationReference,
  toggleCurrentCommunicationLoading
} from './dashboard.action';

function* searchCommunicationSaga({
  payload
}: PayloadAction<{
  token: string;
}>) {
  const {
    entities,
    total
  }: { entities: fhir.Resource[]; total: number } = yield fetchCommunicationSaga();

  const communications: Communication[] = filter(isCommunication)(entities);
  const sortedCommunications: Communication[] = orderBy(['sent'], ['desc'], communications);

  yield put(searchCommunicationSuccess({ communications: sortedCommunications, total }));
}

export function* watchSearchCommunication() {
  yield takeEvery(searchCommunication.type, searchCommunicationSaga);
}

export const EXT_READ_BY_UUID =
  'https://lifen.fr/fhir/StructureDefinition/Communication/Extension/ReadByUuid';

export function* markAsRead(action: PayloadAction<{ reference: string; apiDomain: ApiDomain }>) {
  const { reference, apiDomain } = action.payload;

  if (isUndefined(apiDomain) || isUndefined(reference)) {
    return;
  }
  const communications: EntityObject<Communication> = yield select(getCommunications);
  const communication: Communication = communications[reference];

  if (isUndefined(communication)) {
    return;
  }

  if (!isUndefined(communication.received)) {
    return;
  }

  const fhirClient = $fhir(apiDomain);

  const { user } = yield call(getAuthInfo);
  const uuid = user['http://lifen.fr/userData'].userUuid;
  const extensions: fhir.Extension[] = yield call<typeof addOrUpdateExtension>(
    addOrUpdateExtension,
    communication.extension ?? [],
    EXT_READ_BY_UUID,
    uuid
  );

  yield call<typeof fhirClient.patch>(fhirClient.patch, {
    type: 'Communication',
    id: communication.id ?? '',
    data: [
      { op: 'add', path: '/received', value: moment().format() },
      { op: 'replace', path: '/extension', value: extensions }
    ]
  });
}

export function* getBinariesSaga() {
  yield put(setBinariesRequest([]));
  try {
    const binaries: fhir.Binary[] = yield call(fetchBinariesSaga);
    yield all([
      put(setBinariesSuccess({ binaries })),
      put(toggleCurrentCommunicationLoading(false))
    ]);
  } catch (err) {
    yield put(setBinariesFailure({ err }));
    logger.error('LOGIC', 'Failed to set Binaries', err);
  }
}

export function* onSelectCurrentCommunicationReference(action: any) {
  yield all([
    call<typeof markAsRead>(markAsRead, action),
    call<typeof getBinariesSaga>(getBinariesSaga)
  ]);
}

export function* watchSetCurrentCommunication() {
  yield takeLatest(selectCurrentCommunicationReference.type, onSelectCurrentCommunicationReference);
}

export function* dashboardSagas() {
  yield all([watchSearchCommunication(), watchSetCurrentCommunication()]);
}
