import { connect } from 'react-redux';

import { selectCurrentCommunicationReference } from '../../store/dashboard/dashboard.action';
import {
  getCurrentCommunication,
  getCurrentCommunicationLoading,
  getFeatureFlags
} from '../../store/dashboard/dashboard.selector';
import { getBinaries, getPdfBinaries } from '../../store/entities/selector';
import { State } from '../../store/reducer';
import { ViewerContainer } from './ViewerContainer';

const mapStateToProps = (state: State) => {
  return {
    currentCommunication: getCurrentCommunication(state),
    binaries: getBinaries(state),
    pdfBinaries: getPdfBinaries(state),
    isLoading: getCurrentCommunicationLoading(state),
    featureFlags: getFeatureFlags(state)
  };
};
export default connect(mapStateToProps, {
  setCurrentCommunicationId: (reference: string) =>
    selectCurrentCommunicationReference({ reference })
})(ViewerContainer);
