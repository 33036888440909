import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setError as setErrorAction } from './error.action';
import { LifenError } from './error.type';

type ErrorState = LifenError | null;

const INITIAL_STATE: ErrorState = null;

function setError(_state: ErrorState, errorAction: PayloadAction<LifenError>): ErrorState {
  return errorAction.payload;
}

export const errorReducer = createReducer<ErrorState>(INITIAL_STATE, {
  [setErrorAction.type]: setError
});
