import { Modal } from 'antd';
import React from 'react';

const AnswerModal = ({
  visible,
  setIsVisible
}: {
  visible: boolean;
  setIsVisible: (bool: boolean) => void;
}): JSX.Element => {
  const displaySurvey = () => {
    window.open('https://patients-lifen.paperform.co/', '_blank');
    setIsVisible(false);
  };
  return (
    <Modal
      title="Répondre à votre médecin"
      visible={visible}
      okText="Donner mon avis en 1 min"
      onOk={() => displaySurvey()}
      cancelText="Annuler"
      onCancel={() => setIsVisible(false)}
    >
      <p>
        Vous souhaitez répondre à votre médecin ? Nous y travaillons ! Votre avis nous aiderait
        beaucoup à construire la meilleure expérience pour vous.
      </p>
    </Modal>
  );
};

export default AnswerModal;
