import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { isNil } from 'lodash/fp';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '../../Authentication/Auth0Provider';
import { LifenError } from '../../store/error/error.type';
import { logger } from '../../utils/logger';

interface ErrorAlertProps {
  error: Error;
  setError: (error: LifenError) => void;
}

interface Workspace {
  purpose: 'personal' | 'professional';
  identities: string[];
}

export const ErrorAlert: FC<ErrorAlertProps> = ({ error, setError }) => {
  const { isAuthenticated, user, isLoading }: any = useAuth0();
  useEffect(() => {
    if (!isAuthenticated || !user || isLoading) {
      return;
    }
    const workspaces: Workspace[] = user['http://lifen.fr/workspaces'] || [];
    const personalWS = workspaces.filter(workspace => workspace.purpose === 'personal');
    const identities: string[] = personalWS.reduce((identitiesAcc: string[], ws: Workspace) => {
      if (ws.identities && ws.identities.length) {
        identitiesAcc.push(...ws.identities);
      }
      return identitiesAcc;
    }, []);

    try {
      if (isNil(identities) || identities.length === 0) {
        setError({ message: 'no identities', name: 'UserSetupIssue' });

        throw new Error('User without identities');
      }
    } catch (error) {
      logger.error('UI', 'An error occured', error);
    }
  }, [isAuthenticated, user, setError, isLoading]);

  useEffect(() => {
    if (error === null) {
      return;
    }
    notification.error({
      description:
        "Veuillez contacter le support client à l'adresse https://formcrafts.com/a/lifenpatient",
      message: "Une erreur s'est produite",
      duration: null,
      icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
    });
  }, [error]);

  return null;
};
