import { connect } from 'react-redux';

import { setFeatureFlagsRequest } from '../../store/dashboard/dashboard.action';
import { getFeatureFlags } from '../../store/dashboard/dashboard.selector';
import { State } from '../../store/reducer';
import { FeatureFlags } from '../../utils/featureFlags';
import App from './App';

const mapStateToProps = (state: State) => {
  return {
    featureFlags: getFeatureFlags(state)
  };
};
export default connect(mapStateToProps, {
  setFeatureFlags: (newFlags: FeatureFlags) => setFeatureFlagsRequest(newFlags)
})(App);
