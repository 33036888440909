import { Col, Layout, Row } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { LifenLink } from '../LifenLink';
import lifenOrange from './assets/lifen-orange.png';
import support from './assets/support.svg';

interface FullPageErrorProps {
  title: string;
  children: ReactNode;
}

const standardMargin = 16;

const LayoutFullPage = styled(Layout)`
  height: 100vh;
`;
const ContentAutoScroll = styled(Layout.Content)`
  overflow-y: auto;
`;
const RowFullContainer = styled(Row)`
  height: 100%;
`;
const ContentContainer = styled(Col)`
  background-color: white;
  padding: 64px;
  box-sizing: border-box;
  border-radius: 4px;
`;
const LogoContainer = styled(Row)`
  margin-bottom: ${2 * standardMargin}px;
`;

const Title = styled(Row)`
  text-align: center;
  font-size: 24px;
  margin-bottom: ${2 * standardMargin}px;
`;

const SupportImg = styled.img`
  margin-right: ${standardMargin / 2}px;
`;

const Footer = styled(Layout.Footer)`
  text-align: center;
`;

const FullPageError = ({ title, children }: FullPageErrorProps) => {
  return (
    <>
      <LayoutFullPage>
        <ContentAutoScroll>
          <RowFullContainer justify="center" align={'middle'}>
            <ContentContainer md={12} xs={24} lg={12}>
              <LogoContainer>
                <img src={lifenOrange} alt="lifen-logo" width="152" height="auto" />
              </LogoContainer>
              <Row>
                <Title>{title}</Title>
              </Row>
              <div>{children}</div>
            </ContentContainer>
          </RowFullContainer>
        </ContentAutoScroll>
        <Footer>
          <SupportImg src={support} alt="" />
          Support lifen:{' '}
          <LifenLink href="mailto:support-patient@lifen.fr">support-patient@lifen.fr</LifenLink>
        </Footer>
      </LayoutFullPage>
    </>
  );
};

export { FullPageError };
